import ScenarioResource from 'common/services/resources/scenario.js'

import defaultSkin from './skins/default.vue'
import oneLineSkin from './skins/one-line.vue'

export default {
  components: {defaultSkin,oneLineSkin},
  props: ['widget','section','skin','type'],
  data:
		function () {
			return {
        CONSTANTS:{
          SCENARIO_ENABLE_SUCCESS : this.$gettext('The scenario has been successfully enabled'),
          SCENARIO_ENABLE_ERROR : this.$gettext('Unable to enable the scenario'),
          SCENARIO_DISABLE_SUCCESS : this.$gettext('The scenario has been successfully disabled'),
          SCENARIO_DISABLE_ERROR : this.$gettext('Unable to disable the scenario')
        },
			}
	},
  created: function () {
  },
  methods: {
      trigger: function(){
        if(this.widget.scenario)
          ScenarioResource.trigger(this.widget, this.eventHub);
      },
			enable: function(){
        var _this = this;
        var commandId = this.uuidV4();
        this.widget[this.type].status[0].value = (this.widget[this.type].status[0].value=='disable'?'enable':'disable');
        var json = {
          "status": [
            {
              "capability": "active",
              "value": this.widget[this.type].status[0].value
            }
          ],
          "commandId": commandId
        }
        if(this.widget.scenario){
          ScenarioResource.status(json,this.widget.id).then(
            function(response) {
              _this.eventHub.$emit('displayAlert', _this.CONSTANTS['SCENARIO_'+_this.widget.scenario.status[0].value.toUpperCase()+'_SUCCESS'], 'success');
            }).catch(
            function() {
              _this.eventHub.$emit('displayAlert', _this.CONSTANTS['SCENARIO_'+_this.widget.scenario.status[0].value.toUpperCase()+'_ERROR']);
          });
        }
			}
  }
}
