import CommonResource from 'common/services/resources/common.js'
import GoogleResource from 'common/services/resources/google.js'
import CameraResource from 'common/services/resources/camera.js'
import CordovaService from 'common/services/cordova.js'
import LinkwilPluginService from 'common/services/cordova/linkwilPlugin.js'
import ExternalResource from 'common/services/resources/external.js'
import SmartCamera from 'common/classes/SmartCamera.js'

import ImageWidget from './image/image.vue'
import AccessControlWidget from './access_control/access_control.vue'
import SwitchWidget from './switch/switch.vue'
import CameraWidget from './camera/camera.vue'
import OnOffWidget from './on-off/on-off.vue'
import WeatherWidget from './weather/weather.vue'
import TvWidget from './tv/tv.vue'
import PlayWidget from './play/play.vue'
import UpDownWidget from './up-down/up-down.vue'
import AlarmWidget from './alarm/alarm.vue'
import TextWidget from './text/text.vue'
import DeviceWidget from './device/device.vue'
import RelayWidget from './relay/relay.vue'
import TemperatureHumidityWidget from './temperature-humidity/temperature-humidity.vue'
import HeatingWidget from './heating/heating.vue'
import RgbWidget from './rgb/rgb.vue'
import AccessWidget from './access/access.vue'
import NumberWidget from './number/number.vue'
import PushButtonWidget from './push-button/push-button.vue'
import StartStopWidget from './start-stop/start-stop.vue'
import RecordWidget from './record/record.vue'
import StatusSpeedWidget from './status-speed/status-speed.vue'
import SpeechRecognitionWidget from './speech-recognition/speech-recognition.vue'
import GroupWidget from './group/group.vue'
import LinkwilCameraWidget from './linkwil-camera/linkwil-camera.vue'
import BrightnessWidget from './brightness/brightness.vue'
import DeviceControl from './control-device/control-device.vue'


import { consoleError } from 'vuetify/es5/util/console'

export default {
  props: ['noName','settings','widget', 'models', 'section', 'devices', 'use', 'type', 'externalAccounts', 'widgetModel','sharesLocations'],
  // use => 'scenario-action', 'scenario-trigger' to scenario section
  components: {
    ImageWidget,
    PlayWidget, 
    UpDownWidget,
    AlarmWidget, 
    SwitchWidget, 
    CameraWidget, 
    OnOffWidget, 
    WeatherWidget, 
    TvWidget, 
    TextWidget, 
    DeviceWidget, 
    DeviceControl, 
    RelayWidget, 
    TemperatureHumidityWidget, 
    HeatingWidget, 
    SpeechRecognitionWidget,
    RgbWidget,
    AccessWidget,
    NumberWidget,
    PushButtonWidget,
    StartStopWidget,
    RecordWidget,
    StatusSpeedWidget,
    GroupWidget,
    LinkwilCameraWidget,
    BrightnessWidget,
    AccessControlWidget},
  data:
    function () {
      return {
        CONSTANTS: {
          SYNCHRONIZE_ERROR : this.$gettext('The synchronization request could not be sent'),
          SYNCHRONIZE_SUCCESS : this.$gettext('The synchronization request has been sent'),
          SYNCHRONIZE_AMAZON : this.$gettext("Your Amazon Alexa devices automatically synchronize with ComfortLife"),
          NOTIFICATION_NONE_SUCCESS : this.$gettext('Disabled notifications on %{name}'),
          NOTIFICATION_DEFAULT_SUCCESS : this.$gettext('Notifications enabled on %{name}'),
          NOTIFICATION_NONE_ERROR : this.$gettext('Unable to disable notifications on %{name}'),
          NOTIFICATION_DEFAULT_ERROR : this.$gettext('Unable to activate notifications on %{name}'),
          CAMERA_DESKTOP : this.$gettext('This camera is not compatible with web browser. <br/> Please use mobile app to view camera stream.'),
          DEFAULT_WIDGET : {
            'switch' : ['default_active', 'default_status'],
            'up/down' : ['default_position_vertical'],
            'access' : ['default_access'],
            'speed' : ['default_speed'],
            'status/speed' : ['default_status_and_speed'],
            'alarm' : ['default_protection'],
            'rgb' : ['default_rgb'],
            'play' : ['default_show','default_trigger'], 
            'number' : ['default_humidity','default_pressure','default_temperature','default_wind_angle','default_wind_strength'],
            'push-button' : ['default_dock','default_snapshot','default_unlock'], 
            'record' : ['default_record'],
            'start/stop' : ['default_operation','default_stream'],
            'device' : ['default_alert','default_detect','default_ring'],
            'position' : ['default_position'],
            'brightness' : ['default_brightness'],
            'on/off' : ['simple_status'],
            'temperature/humidity' : ['default_temperature_and_humidity']
          },
          CAMERA_MODELS : ['camera', 'smart_camera', 'vdp', 'battery_vdp', 't21', 'oskkio-1']
        },
        waiting: false,
        displayPilotModal: false,
        displayInstallModal: false,
        selectedModule: {},
        modelForControlDeviceModal: '',
        color: '',
        widgetNameStyle :
        {
          width: '100%',
        },
        cameraErrorModal: false,
        verificationCode:''
      }
    },
  created: function () {
    this.eventHub.$on('goToNotification', this.goToNotification);
    this.eventHub.$on('controlDevice_'+this.widget.id, this.controlDevice);
    this.eventHub.$on('cameraError', this.cameraError);
    this.eventHub.$on('setSelectedModule_'+this.widget.id, this.setSelectedModule);
    this.initSubsocket();
    if(this.section == 'room'){
      this.color = 'color:#3C3C3C;'
    }
  },
  mounted: function () {
    this.$nextTick(function() {
      this.widgetNameStyleObject();
    });
  },
  destroyed: function () {
    this.eventHub.$off('goToNotification', this.goToNotification);
    this.eventHub.$off('controlDevice_'+this.widget.id, this.controlDevice);
    this.eventHub.$off('cameraError', this.cameraError);
    this.eventHub.$off('setSelectedModule_'+this.widget.id, this.setSelectedModule);
  },
  computed: {
    widgetContainerCls: function () {
      var clsObj = {
        noName: (this.noName!=undefined)
      };
      clsObj[this.section] = true;
      return clsObj;
    },
  },
  methods: {
    initSubsocket: function(){
      if(this.widget && this.widget.device && this.widget.device.model=='subsocket'){
        this.widget.device.brand = 'legrand_home_plus_control';
        this.widget.device.model = 'plug';
      }
    },
    widgetNameStyleObject: function () {
      if(this.$refs['widgetName'])
      {
        var contentSize = this.$refs['widgetName'].offsetWidth;
        this.widgetNameStyle.width = contentSize+'px';
        this.lineClamp(this.$refs['widgetName'], 2);
      }
    },

    setSelectedModule : function(){
      this.displayPilotModal=false;
      var _this = this;
      
      setTimeout(function(){ 
        _this.displayPilotModal=true;
      }, 1000);
      //this.controlDevice();
      //this.selectedModule = this.module;
    },

    setStatus : function(status, subItem){
      var deviceUid = subItem;
      if(deviceUid)
        deviceUid = (typeof subItem === 'string'?subItem:subItem.id);
      if(this.widget.className && this.widget.className =='Group')
      {
        CommonResource.sendGroupStatus(this.eventHub,this.widget,[status],'device/'+this.widget.device.systems[0]+'/addressLocation/plants/'+this.$store.state.selectedPlantId+'/zones/parameter/id/value/'+this.widget.roomId);
      }
      else
      {
        CommonResource.setStatus(this.widget, status, deviceUid, this.eventHub);
      }
    },

    setStatusService : function(status, id, service, widget){
      CommonResource.setStatusService(status, id, service, widget, this.eventHub);
    },

    controlDevice : function(info){
      //if(this.widget.id == module.id){
        
        //this.selectedModule = module;
        if(info)
        {
          this.widget.info = info;
        }
        else if(info == undefined && module.device && module.device.model == "vdp")
        {
          this.widget.info = null;
        }
        
        /*if(this.widget.device && this.widget.device.model == "vdp"){
          this.widget.type = "custom";
          //this.widget.info = "call";
        }*/
        this.modelForControlDeviceModal = (this.widget.device?this.widget.device.model:(this.widget.service?this.widget.service.model:''));
        if(this.widget.device && this.widget.device.model == 'camera'){
          if(this.inApp)
          {
            var ea = ExternalResource.getExternalAccountsByModule(this.widget.id,this.sharesLocations,this.externalAccounts);
            CordovaService.launchFullscreen(this.eventHub, ea,this.widget,(this.$route.name == 'view'?'landscape':'portrait'),this.uuidV4());
          }
          else{
            this.eventHub.$emit('displayAlert', this.CONSTANTS.CAMERA_DESKTOP);
          }
        }
        else if(this.widget.device && this.widget.device.model == 'homepilot' 
        && this.widget.device.submodel != 'hp_200'
        && this.widget.device.submodel != 'hp_0001'){
          if(!this.eventHub.displayFeatureNotAvailableSharedAccount())
          {
            return false;
          }
          this.eventHub.$emit('addDevice', this.widget);
        }
        else if(this.widget.device && this.widget.device.model == 'google_home'){
          this.synchronizeGoogle();
        }
        else if(this.widget.device && this.widget.device.model == 'amazon_echo'){
          this.eventHub.$emit('displayAlert', this.CONSTANTS.SYNCHRONIZE_AMAZON, 'info');
        }
        else if(this.widget.className && this.widget.className =='Group'){
          this.displayPilotModal=true;
        }
        else if(this.widget.service){
          this.displayPilotModal=true;
        }
        else{
          if(this.widget.device.subdeviceModels)
          {
            this.displayPilotModal = true;
          }
          else if(_find(this.widget.device.capabilities, function(m){return m.executable;})!=undefined)
          {
            this.displayPilotModal = true;
          }
        }
      //}
    },


    goToNotification : function(n) {
      this.$router.push({ name: 'alerts', params: {notificationUid: n.id }});
    },

    isOnOff : function(){
      return (this.widget.device.status && this.widget.device.status[0].capability == 'status');
    },

    isCamera : function(){
      return (this.CONSTANTS.CAMERA_MODELS.includes(this.widget.device.model));
    },

    synchronizeGoogle : function(){
      var _this = this;
      GoogleResource.synchronize().then(
        function() {
            _this.eventHub.$emit('displayAlert', _this.CONSTANTS.SYNCHRONIZE_SUCCESS, 'success');
        }).catch(
          function() {
            _this.eventHub.$emit('displayAlert', _this.CONSTANTS.SYNCHRONIZE_ERROR);
        });
    },

    defaultWidget: function(value){
      var widgetModelName = '';
      if(this.widgetModel != undefined)
        widgetModelName = this.widgetModel;
      else
        widgetModelName = this.widget.device.defaultWidgetModel;
      return this.CONSTANTS.DEFAULT_WIDGET[value].indexOf(widgetModelName)!=-1
    },
    
    //CAMERA
    cameraError: function (error,module,uuidV4) {
        var _this = this;
        if(module.id!=this.widget.id) return false;
        if(error.type=='verificationCodeError')
        {
          this.showCameraErrorModal();
        }
        else if(error.type=='accessTokenError')
        {
          var brandName = module.device.brandObj.name;
          var ea = ExternalResource.getExternalAccountsByModule(this.widget.id,this.sharesLocations,this.externalAccounts);
          ExternalResource.accountRefresh(brandName,this.$store.state.plantUser).then(
            function(response) {
                var externalAccount = _find(ea, {external:{name:_this.widget.device.brand.toLowerCase()}});
                externalAccount.account.accessToken = response.data.accessToken;
                CordovaService.launchFullscreen(_this.eventHub, ea,_this.widget,(_this.$route.name == 'view'?'landscape':'portrait'),uuidV4);
            }).catch(
              function(e) {
                console.log(e);
                error.initializeToGeneral();  
                _this.eventHub.$emit('displayAlert', _this.$gettext(error.txt)+(Number.isInteger(error.errorCode)?' ('+_this.$gettextInterpolate(_this.$gettext('error: %{errorCode}'), {errorCode: error.errorCode })+')':''));
            });
        }
        if(error.type!='accessTokenError')
          this.eventHub.$emit('displayAlert', this.$gettext(error.txt)+(Number.isInteger(error.errorCode)?' ('+this.$gettextInterpolate(this.$gettext('error: %{errorCode}'), {errorCode: error.errorCode })+')':''));
    },

    showCameraErrorModal: function () {
        this.verificationCode = (this.widget.device.verifyCode?this.widget.device.verifyCode:'');
        this.cameraErrorModal = true;
    },

    checkVericationCode: function () {
      var max = 12;
      if (this.verificationCode.length > max) {
          this.verificationCode = this.verificationCode.substr(0, max);
      }
    },
    sendVerificationCode: function () {
        this.widget.device.verifyCode = this.verificationCode;
        this.cameraErrorModal = false;
        var _this = this;
        var ea = ExternalResource.getExternalAccountsByModule(this.widget.id,this.sharesLocations,this.externalAccounts);
        CordovaService.launchFullscreen(this.eventHub, ea,this.widget,(this.$route.name == 'view'?'landscape':'portrait'),this.uuidV4());
        CameraResource.setVerifyCode(this.widget.id,this.widget.device.verifyCode).then(
          function(response) {
            _this.eventHub.$emit('setDevice', _this.widget, 'verifyCode',_this.verificationCode);
          }).catch(
          function() {});
    },

    closeModalDeviceControl: function () {
      this.displayPilotModal = false;
      if(this.widget.device)
      {
        if(this.widget.device.submodel == 'vdp_100')
        {
          LinkwilPluginService.stopLinkwil();
        }
      }
    },  

  }
}

