export default {
    props: ['widget', 'section'],
    data:
        function () {
            return {
                color:''
            }
    },
    computed: {
        icon: function () {
            var state = this.widget.device.status[0].value;
            return (state == 'start' ? 'record' : 'stop');
        }
    },
    created: function () {
      this.initColor();
    },
    destroyed: function () {
    },
    methods: {
      initColor: function(){
        if(this.widget.notification_level == 'ALERT')
          this.color = "red-badge";
        else
          this.color = "grey-badge";
      },
      status: function(){
        var status = (this.widget.device.status[0].value=='start'?'stop':'start');
        this.$emit('set-status', status);
			}
    }
  }
  