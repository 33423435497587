import Global from './global.js'
import StorageService from '../storage.js'
import axios from 'axios'

export default {
    synchronize: function () {    
        return new Promise(
            function(resolve, reject){
                axios.post(Global.getBackendUrl()+'google/smart/home/synchronize',
                    {},
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
}
