import store from 'vuex_path/store';

export default {
    props: ['widget', 'section'],
    data:
            function () {
                return {
                }
    },
    computed: {
        capabilityStatus () {
            var capabilityStatus = 'off';
            var allIndex = [];
            var nbONDevices = 0;
            this.widget.device.devices.forEach(function(device) {
                var index = store.state.outdoorlights.devices.indexOf(device);
                allIndex.push(index);
            });
            for(var i = 0; i<allIndex.length;i++)
            {
                if(store.state.outdoorlights.value.charAt(allIndex[i])=='1'){
                    nbONDevices++;
                }
            }
            if(nbONDevices>0)
            {
                capabilityStatus = (nbONDevices==allIndex.length?'on':'partial');
            }
            
            return capabilityStatus;
        }
      },
    methods: {
        allOff: function(){
            return (this.capabilityStatus=='off');
        },
        setStatus: function(){
            var _this = this;
            //this.capabilityStatus = (this.capabilityStatus=='off'?'on':'off');
            var allIndex = [];
            this.widget.device.devices.forEach(function(device) {
                var index = store.state.outdoorlights.devices.indexOf(device);
                allIndex.push(index);
            });
            var value = JSON.parse(JSON.stringify(store.state.outdoorlights.value));
            allIndex.forEach(function(index) {
                value = value.substr(0, index) + (_this.capabilityStatus=='on'?'0':'1') + value.substr(index + 1);
            });
            store.state.outdoorlights.value = value;
            this.$emit('set-status', store.state.outdoorlights.value);
        }
    }
}
