export default {
    props: ['widget', 'section', 'widgetModel'],
    data:
        function () {
            return {
                unity: '',
                model: '',
                decimal: '.0'
            }
    },
    computed: {
        value: function () {
            var value = _find(this.widget.device.status, {'capability': this.model});
            var number = 'O';
            var decimal = '.O'
            if(value && value.value)
            {
                var splitValue = value.value.split('.');
                number = splitValue[0];
                decimal = '.'+(splitValue.length>1?splitValue[1]:'0');
                this.decimal = decimal.trim();
            }
            return number;
        },
        temperatureClass: function () {
            var cls = {};
            var position = _find(this.widget.device.status, {'capability':'temperatureDiff'});
            cls['color-lightblue'] = false;
            cls['color-notificationred'] = false;
            if(position && this.model == 'temperature')
            {
              if(position.value=='<')
              {
                cls['color-lightblue'] = true;
              }
              else if(position.value=='>')
              {
                cls['color-notificationred'] = true;
              }
            }
            return cls;
        }
    },
    created: function () {
        if(this.widgetModel != undefined)
            this.model = this.widgetModel
        else
            this.model = this.widget.device.defaultWidgetModel
        var tab = this.model.split('_');
        this.model = tab[1];
        this.initUnity();
    },
    methods: {
        initUnity: function(){
            switch(this.model){
                case 'pressure' :
                    this.unity = '%';
                    break;
                case 'humidity' :
                    this.unity = '%';
                    break;
                case 'temperature' :
                    this.unity = '°C';
                    break;
                case 'wind_angle' :
                    this.unity = '°';
                    break;
                case 'wind_strength' :
                    this.unity = 'km/h';
                    break;
            }
        }
    }
  }