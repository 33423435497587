import CordovaService from 'common/services/cordova.js'
import moment from 'moment'
import SmartCamera from 'common/classes/SmartCamera.js'
import ExternalResource from 'common/services/resources/external.js'

export default {
  props: ['widget','externalAccounts','section','devices', 'settings', 'sharesLocations'],
  data:
		function () {
			return {
                CONSTANTS: {
                  DISPLAY_TIMEOUT: 40000,
                  DISPLAY_BEBUG_LOG_TIMEOUT: 20000,
                  STOP_TIMEOUT: 1000
                },
                forward: 0,
                toastMsg: '',
                debugLog: '',
                loading:false,
                datetime: moment(),
                timeoutStartTimeout: undefined,
                timeout: undefined,
                timeoutDebugLog: undefined,
                timeoutStopStream: undefined
			}
    }, 
  created: function () {
    if(this.widget.device.model == 'smart_camera' 
    || this.widget.device.model == 'vdp'
    || this.widget.device.model == 'battery_vdp'
    || this.widget.device.model == 't21'
    || this.widget.device.model == 'oskkio-1'
    )
    {
      this.eventHub.$on('camera_onShow_'+this.widget.id, this.onShow);
      this.eventHub.$on('camera_onStop_'+this.widget.id, this.onStop);
    }
  },
  destroyed: function () {
    if(this.widget.device.model == 'smart_camera' 
    || this.widget.device.model == 'vdp'
    || this.widget.device.model == 'battery_vdp'
    || this.widget.device.model == 't21'
    || this.widget.device.model == 'oskkio-1'
    )
    {
      this.eventHub.$off('camera_onShow_'+this.widget.id, this.onShow);
      this.eventHub.$off('camera_onStop_'+this.widget.id, this.onStop);
    }
  },
  methods: {

    modalManu : function(){
        this.eventHub.$emit('controlDevice_'+this.widget.id);
    },

    onClickWidget : function(){  
      /*CordovaService.startKVSStream(this.eventHub,this.widget.id,'eu-west-1'); 
      return false;*/
      var ea = ExternalResource.getExternalAccountsByModule(this.widget.id,this.sharesLocations,this.externalAccounts);
      /*var externalAccount = _find(ea, {external:{name:this.widget.device.brand.toLowerCase()}});
      var accessToken = (externalAccount && externalAccount.account? externalAccount.account.accessToken:'');
      var appKey = (externalAccount && externalAccount.external? externalAccount.external.appKey:'');
      console.log(accessToken);
      console.log(this.widget.device.deviceSerial);
      console.log(this.widget.device.channelNo);
      console.log((this.widget.device.verifyCode?this.widget.device.verifyCode:''));
      console.log(appKey);*/
      
      if(this.loading) return false;    
      
      if(this.widget.device.model == 'camera'){
        (this.inApp?CordovaService.launchFullscreen(this.eventHub, ea,this.widget,(this.$route.name == 'view'?'landscape':'portrait'),this.uuidV4()):this.modalManu());
      } 
      else 
      {      
        this.loading = true;
        if(this.widget.device.class==undefined || this.widget.device.class.show==undefined)
        {
          var d = _find(this.devices, {id:this.widget.id});
          d.device.class  = new SmartCamera(this.eventHub, d, this.inApp);
          d.device.class.show('hd');
        }
        else
        {
          this.widget.device.class.show('hd');
        }
      }
      //this.eventHub.$emit('cameraError',new CameraErrorCode(400035),this.widget);
    },

    onShow : function(){ 
      this.loading = true;
    },

    onStop : function(){ 
      this.loading = false;
    }
 }
}
