import VueSlider from 'vue-slider-component'
import 'vendor/vue-slider-component/theme/default.css'
export default {
    props: ['widget', 'section'],
    data:
		function () {
			return {
                color:'',
                brightnessIndex:-1,
                statusIndex:-1,
                sliderOptions: {
                    min: 0,
                    max: 254,
                    interval: 1,
                    clickable: true,
                    tooltip: false
                }
			}
        },
    components: {
        VueSlider
    },
    created: function () {
        this.brightnessIndex = _findIndex(this.widget.device.status, {'capability':'brightness'});
        this.statusIndex = _findIndex(this.widget.device.status, {'capability':'status'}); 
        this.widget.device.status[this.brightnessIndex].value = 0;  
        this.initColor();
    },
    methods: {
        initColor: function(){
            if(this.widget.notification_level == 'ALERT')
                this.color = "red-badge";
            else
                this.color = "grey-badge";
        },
        status: function(){
            this.widget.device.status[this.statusIndex].value = (this.widget.device.status[this.statusIndex].value=='on'?'off':'on');
            this.$emit('set-status', this.widget.device.status[this.statusIndex]);
        }
    }
}
