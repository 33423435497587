export default {
  props: ['widget', 'section'],
  data:
		function () {
			return {
        decimal: '.0'
			}
	},
  created: function () {
    this.initColor();
  },

  computed: {
    temperature: function () {
      var temperature = _find(this.widget.device.status, {'capability':'temperature'});
      var temp = '0';
      var decimal = '.0';
      if(temperature && temperature.value)
      {
        var splitTemperature = temperature.value.split('.');
        temp = splitTemperature[0];
        decimal = '.'+(splitTemperature.length>1?splitTemperature[1]:'0');
        this.decimal = decimal.trim();
      }
      return temp;
    },
    humidity: function () {
      var humidity = _find(this.widget.device.status, {'capability':'humidity'});
      return (humidity && humidity.value ? humidity.value : '0');
    },  
    /*desiredTemperature: function () {
      var desiredTemperature = _find(this.widget.device.status, {'capability':'desiredTemperature'});
      return (desiredTemperature && desiredTemperature.value ? desiredTemperature.value : '...');
    }, */

    temperatureClass: function () {
      var cls = {};
      var position = _find(this.widget.device.status, {'capability':'temperatureDiff'});
      cls['color-lightblue'] = false;
      cls['color-notificationred'] = false;
      if(position)
      {
        if(position.value=='<')
        {
          cls['color-lightblue'] = true;
        }
        else if(position.value=='>')
        {
          cls['color-notificationred'] = true;
        }
      }
      return cls;
    }
  },

  methods: {
    initColor: function(){
      if(this.widget.notification_level == 'ALERT')
        this.color = "red-badge";
      else
        this.color = "grey-badge";
    }
  }

}
