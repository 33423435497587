export default {
  data:
		function () {
			return {
				temperature: 30,
				humidity: 49,
			}
	},
  created: function () {
  },
  methods: {
 }
}
