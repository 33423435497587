export default {
    props:{
        status: {
            type: String,
			default:'off'
        },
    },
    data:
        function () {
                    return {
                                CONSTANTS:
                                {
                                },
                    }
                },
     created:
        function () {
        }

}
