
import GlobalResource from 'common/services/resources/global.js'
export default {
    props:{
        widget: {
            type: Object,
			default:{}
        },
        section: {
            type: String,
			default:''
        },
        backgroundEmpty: {
            type: String,
			default:'assets/images/rooms/empty.jpg'
		},
		backgroundSize: {
            type: String,
			default:'cover'
		},
		size: {
            type: String,
			default:'100%'
		}
    },
    data:
        function () {
            return {
            }
        },
    created: function () {
    },
    methods: {
        img: function ()
		{
			var style = {};
			try 
			{
				if(this.widget.image!=undefined)
				{
					if(this.widget.image.full.key !=undefined && this.widget.image.thumb !=undefined)
					{
						style.backgroundImage = 'url('+require('@/assets/'+this.$store.state.variantManager.class.defaultRoomsPath+'/'+this.widget.image.full.key+'_thumb.jpg')+')';
					}
					else
					{
						style.backgroundImage = 'url('+GlobalResource.getBackendUrl()+this.widget.image.full.path+')';
					}
				}
				else
				{
					style.backgroundImage = 'url('+require('@/assets/images/rooms/empty.jpg')+')';
				}
			}
			catch(e)
			{
				style.backgroundImage = 'url('+require('@/assets/images/rooms/empty.jpg')+')';
			}
			style.backgroundSize = this.backgroundSize;
			style.backgroundPosition = 'center';
			if(this.widget.shortcut)
			{
				style.height = this.size;
				style.width = this.size;
			}
			return style;
		},
    }
}
  