
import iro from '@jaames/iro';

export default {
  props: ['widget','section'],
  data:
		function () {
			return {
        //colorPicker:{}
      }
  },
  /*mounted: function () {
    this.initColorPicker();
  },*/
  methods: {
      /*initColorPicker: function(){
        this.colorPicker = new iro.ColorPicker(this.$refs['color-picker-container'],{});
      }*/
      controlDevice: function(){
        this.eventHub.$emit('controlDevice_'+this.widget);
      }
  }
}
