export default {
  props: [],
  data:
		function () {
			return {
			}
	},
  created: function () {
  },
  methods: {
 }
}
