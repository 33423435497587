
import { consoleError } from 'vuetify/es5/util/console';
import LightSwitch from 'common/components/light-switch/light-switch.vue'
export default {
  props: ['widget', 'section'],
  components: {LightSwitch},
  data:
		function () {
			return {
        color:'',
        statusOnOffIndex : -1,
        statusState: undefined,
        statusSwitch: 'off'
			}
  },

  created: function () {
    //this.eventHub.$on('refreshModule_'+this.widget.id, this.refreshModule);
    this.statusOnOffIndex = _findIndex(this.widget.device.status,{capability:'status'});
    this.initColor();
    this.initStates();
  },

  destroyed: function () {
    //this.eventHub.$off('refreshModule_'+this.widget.id, this.refreshModule);
  },

  watch: {
    'widget.devices': {
      handler: function (val, oldVal) {
        this.initStates();

      },
      deep: true
    }
  },
  methods: {
      refreshModule: function(module){
        this.statusSwitch = module.statusSwitch;
      },
      backLightCls: function(){
        return (((this.widget.devices == undefined && this.statusOnOffIndex!=-1 && this.widget.device.status[this.statusOnOffIndex].value == 'on') || (['on', 'partial'].indexOf(this.statusState)!=-1)) && this.widget.offline!=true);
      },
      initColor: function(){
        if(this.widget.notification_level == 'ALERT')
          this.color = "red-badge";
        else
          this.color = "grey-badge";
      },

      initStates: function(){
        if(this.widget.devices == undefined) return false;
        var allStatusOn = _filter(this.widget.devices, function(d)
        {
          var status = _find(d.device.status,{capability:'status'});
          if(status && status.value=='on')
          {
            return true;
          }
          else
          {
            return false;
          }
        });
        if(this.widget.devices.length == allStatusOn.length)
        {
          this.statusState = 'on';
          this.widget.device.status[this.statusOnOffIndex].value = 'on';
          //this.statusSwitch = 'on';
        }
        else if(allStatusOn.length == 0)
        {
          this.statusState ='off';
          this.widget.device.status[this.statusOnOffIndex].value = 'off';
          //this.statusSwitch = 'off';
        }
        else
        {
          this.statusState ='partial';
        }
      },

			status: function(s){
        var status = (s?s:(this.widget.device.status[this.statusOnOffIndex].value == 'on'?'off':'on'));
        //this.statusSwitch = status;
        /*if(this.widget.constructor.name=='Group')
        {
          CommonResource.sendGroupStatus(this.eventHub,this.widget,[{capability:'status',value:status}],'device/'+this.widget.device.systems[0]+'/addressLocation/plants/'+store.state.selectedPlantId+'/zones/parameter/id/value/'+this.widget.roomId);
        }
        else
        {*/
          this.$emit('set-status', {capability:'status',value:status});
        //}
      }
  }
}
