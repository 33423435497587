export default {
  props: ['widget', 'section'],
  data:
		function () {
			return {
        color:''
			}
  },
  created: function () {
    this.initColor();
  },
  methods: {
      initColor: function(){
        if(this.widget.notification_level == 'ALERT')
          this.color = "red-badge";
        else
          this.color = "grey-badge";
      },
      switchStatus: function(){
        if(this.widget.device.capabilities[0].states.indexOf('instant_up')!=-1)
        {
          this.$emit('set-status', (this.widget.device.status[0].value=='down'?'instant_up':'instant_down'));   
        }
			},
			status: function(status){
        this.$emit('set-status', status);
			}
  }
}
