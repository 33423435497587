export default {
  props: ['widget', 'section'],
  data:
		function () {
			return {
        color:'',
        iconColor: 'color-darkgray-2'
			}
  },
  created: function () {
    this.initColor();
    this.eventHub.$on('widgetAlert', this.widgetAlert);
  },
  destroyed: function () {
    this.eventHub.$off('widgetAlert', this.widgetAlert);
  },
  methods: {
    initColor: function(){
      if(this.widget.notification_level == 'ALERT')
        this.color = "red-badge";
      else
        this.color = "grey-badge";
    },
    widgetAlert: function(id){
      if(this.widget.id == id){
        this.iconColor = 'color-red';
        var _this = this;
        var interval = setInterval(function(){ 
            _this.iconColor = (_this.iconColor=='color-red'?'color-darkgray-2':'color-red');
        }, 500);
        setTimeout(function(){ 
            _this.iconColor = 'color-darkgray-2';
            clearInterval(interval); 
        }, 5000);
      }
    },
    controlDevice: function(){
      this.eventHub.$emit('controlDevice_'+this.widget.id);
    }
  }
}
