import VueSlider from 'vue-slider-component'
import 'vendor/vue-slider-component/theme/default.css'
export default {
    props: ['widget', 'section', 'type'],
    data:
		function () {
			return {
                color:'',
                speedIndex:-1,
                sliderWidth: 200,
                sliderOptions: {
                    dotSize: 14,
                    eventType: 'auto',
                    min: 0,
                    max: 3,
                    interval: 1,
                    clickable: true,
                    disabled: false,
                    tooltip: false,
                    piecewise: true,
                    speed: 0.5,
                    processStyle: {
                        "backgroundColor": "#3C3C3C"
                    },
                    piecewiseActiveStyle: {
                        "backgroundColor": "#fff"
                    }  
                }
			}
        },
    components: {
        VueSlider
    },
    created: function () {
        this.speedIndex = _findIndex(this.widget.device.status, {'capability':'speed'});       
        this.initColor();
    },
    destroyed: function () {
    },
    methods: {
        initColor: function(){
            if(this.widget.notification_level == 'ALERT')
                this.color = "red-badge";
            else
                this.color = "grey-badge";
        },
        status: function(){
            status = (this.widget.device.status[0].value=='on'?'off':'on');
            this.$emit('set-status', status);
        }
    }
}
