export default {
    props: ['widget', 'section'],
    data:
        function () {
            return {
                color:'',
            }
    },
    created: function () {
      this.initColor();
    },
    destroyed: function () {
    },
    methods: {
      initColor: function(){
        if(this.widget.notification_level == 'ALERT')
          this.color = "red-badge";
        else
          this.color = "grey-badge";
      },
      status: function(){
        this.$emit('set-status');
			},
    }
  }
  