import { render, staticRenderFns } from "./camera.vue?vue&type=template&id=76f34014&scoped=true&"
import script from "./camera.js?vue&type=script&lang=js&"
export * from "./camera.js?vue&type=script&lang=js&"
import style0 from "./camera.css?vue&type=style&index=0&id=76f34014&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "76f34014",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VBadge,VProgressCircular})
