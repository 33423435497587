export default {
    props: ['widget', 'section'],
    data:
          function () {
              return {
              }
      },
    created: function () {
      this.initColor();
    },
  
    methods: {
      clickOnWidget: function () {
        if(this.widget.service
          && this.widget.service.infos
          && this.widget.service.infos.actions
          && this.widget.service.infos.actions.length>0
          && this.widget.service.infos.actions[0].infos
          )
        {
          const idCamera = this.widget.service.infos.actions[0].infos.id;
          let d = _find(this.$store.state.allDevices, function(d) { return d.id == idCamera; });
          if(d)
          {
            this.eventHub.$emit('showCamera',d);
          }
        }
      },
      initColor: function(){
        if(this.widget.notification_level == 'ALERT')
          this.color = "red-badge";
        else
          this.color = "grey-badge";
      },
      
    }
  
  }