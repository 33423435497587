export default {
    props: ['widget', 'section', 'type'],
    data:
          function () {
              return {
                decimal: '.0'
              }
      },
    created: function () {
      this.initColor();
    },
    computed: { 
      state: function () {
        if(this.widget.service && this.widget.service.status  ){
          const thermostat = _find(this.widget.service.status, {'capability':'thermostat'});
          if(thermostat)
          {
            return thermostat.value;
          }
        }
        return undefined;
      }, 
      temperature: function () {
        if(this.widget.sensor){
          var temperature = _find(this.widget.sensor[0].device.status, {'capability':'temperature'});
        }
        else{
          this.decimal = '.0';
        }
        var temp = '0';
        var decimal = '.0';
        if(temperature && temperature.value)
        {
          var splitTemperature = temperature.value.split('.');
          temp = splitTemperature[0];
          decimal = '.'+(splitTemperature.length>1?splitTemperature[1]:'0');
          this.decimal = decimal.trim();
        }
        return temp;
      }, 

      humidity: function () {
        if(this.widget.sensor && this.widget.sensor.length>0)
        {
          var humidity = _find(this.widget.sensor[0].device.status, {'capability':'humidity'});
          return (humidity && humidity.value ? humidity.value : '0');
        }
        return '0';
      },  

      desiredTemperature: function () {
        if(this.widget.sensor && this.widget.sensor.length>0){
          var desiredTemperature = _find(this.widget.sensor[0].device.status, {'capability':'desiredTemperature'});
          return (desiredTemperature && desiredTemperature.value ? desiredTemperature.value : '...');
        }
        return '...';
      }, 
  
      temperatureClass: function () {
        if(this.widget.sensor && this.widget.sensor.length>0){
          var cls = {};
          var position = _find(this.widget.sensor[0].device.status, {'capability':'temperatureDiff'});
          cls['temperature-above'] = false;
          cls['temperature-below'] = false;
          if(position && this.state=="auto")
          {
            if(position.value=='<')
            {
              cls['temperature-below'] = true;
            }
            else if(position.value=='>')
            {
              cls['temperature-above'] = true;
            }
          }
        }
        return cls;
      }
    },
  
    methods: {
        initColor: function(){
          if(this.widget.notification_level == 'ALERT')
            this.color = "red-badge";
          else
            this.color = "grey-badge";
        }
    }
  
  }