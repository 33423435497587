export default {
  props: ['widget', 'section', 'type'],
  data:
		function () {
			return {
        color:''
			}
  },
  created: function () {
    this.initColor();
  },
  methods: {

			status: function(status){
        this.widget[this.type].status[0].value = status;
        this.$emit('set-status', this.widget[this.type].status[0].value, this.widget.id, 'alarm', this.widget);
      },

      initColor: function(){
        if(this.widget.notification_level == 'ALERT')
          this.color = "red-badge";
        else
          this.color = "grey-badge";
      }
  }
}
