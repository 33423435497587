export default {
  props: ['widget','type'],
  data:
		function () {
			return {
			}
	},
  created: function () {
  },
  methods: {
      enable: function(){
        this.$emit('enable')
      },   
			trigger: function(){
        this.$emit('trigger');
			}
  }
}
